<template>
    <!-- Full screen modal -->

    <div class="modal fade" ref="searchModal" id="searchModal" tabindex="-1" style="width: 100vw !important;">
        <div class="modal-dialog" style="width: 100vw !important;">
            <div class="modal-content p-5" style="width: 100vw !important;">
                <button type="button" class="btn-close end-0 me-5 position-absolute" @click="closeModal"
                    data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="p-4 d-flex justify-content-center align-items-start pb-0 mb-0 mt-5 pt-5">
                    
                    <div class="col-lg-8 col-12">
                        <div class="d-flex form-inputs input-group mb-3 ">
                            <input class="form-control secondary-font" v-model="search" type="text" v-on:keyup.enter="searchData"  placeholder="Search">
                            <button class="btn btn-success " @click="searchData" type="button" id="button-addon2"><i class="fa fa-search hover-green"></i></button>
                            <!-- <span style="cursor: pointer;"></span> -->
                        </div>
                        <div class="row d-flex justify-content-center my-5" v-if="loading">
                            <div class="spinner-border text-success" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div class="mx-1" v-if="!loading">
                            <p class="fs-6 mt-5 mb-2 pb-0" v-if="Object.keys(datas).length">Result:</p>
                            <div v-for="(data, index) in displayedItems" :key="index" class="row mx-0 px-0 mb-4">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title secondary-font">{{ data.SearchKeyword }}</h5>
                                        <h6 class="card-subtitle mb-2 text-muted secondary-font">{{ data.SearchHref }}</h6>
                                        <a :href="hrefSearch(data.SearchHref)" class="stretched-link"></a>
                                    </div>
                                </div>
                            </div>
                            
                            <div v-if="Object.keys(datas).length && totalPages > 1" class="d-flex justify-content-center">
                                <button class="btn btn-success" style="border-radius: 20px 0px 0px 20px; width: 100px" @click="changePage(currentPage - 1)" :disabled="currentPage===1">Previous</button>
                                <select v-model="currentPage" class="form-select" style="border-radius: 0px 0px 0px 0px; width: 75px;">
                                    <option v-for="page in totalPages" :key="page" :value="page">{{ page }}</option>
                                </select>
                                <button class="btn btn-success" style="border-radius: 0px 20px 20px 0px; width: 100px"  @click="changePage(currentPage + 1)" :disabled="currentPage===totalPages">Next</button>
                            </div>
                            <div v-if="Object.keys(datas).length == 0 && searchBool == true"> No Result Found</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <header class="header default bg-transparent d-none d-lg-block ">

        <nav class="navbar navbar-expand-lg fixed-top " :class="getMainNavClasses"
            aria-label="Thirteenth navbar example">
            <div class="container-lg">
                <button class="navbar-toggler d-lg-none" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                    <span class="navbar-toggler-icon"></span>
                </button>


                <div class="collapse navbar-collapse d-lg-flex justify-content-between" id="navbarsExample11">
                    <nav class="navbar ">
                        <div class="container">
                            <a class="navbar-brand" href="/">
                                <img src="../assets/logo-wiraraja.png" alt="Wiraraja" height="80">
                            </a>
                        </div>
                    </nav>
                    <ul class="navbar-nav  col justify-content-lg-end ">
                        <li class="nav-item">
                            <a class="nav-link text-capitalize" href="/"
                                :class="[getNavLinkClasses, { 'routerActive': $route.name == 'home' }]">Home</a>

                        </li>
                        <li class="nav-item" @mouseenter="openMegaMenu" @mouseleave="closeMegaMenu">
                            <a class="nav-link text-capitalize" @click="openMegaMenu" style="cursor: pointer;" href="/about-us"
                                :class="[getNavLinkClasses, { 'routerActive': $route.name == 'about-us' || $route.name == 'milestone' || $route.name == 'management-team' }]"
                                role="button" aria-current="page">About Us</a>

                            <div ref="megamenu" v-if="megaMenuVisible"
                                class="container-sm position-absolute top-200 start-50 translate-middle mega-menu bg-white p-0 rounded-3 shadow-lg">
                                <div class="row m-0 p-0 rounded-3 d-flex bg-white p-4 align-items-center flex-wrap">
                                    <div class="col-4 p-3 text-start rounded-3 border-end h-100">
                                        <ul class="" style=" list-style-type: none;">
                                            <li class="link-megamenu mb-4 cursor-pointer" style="cursor:pointer"
                                                @mouseenter="initialID = 1">
                                                <a href='/about-us' style="text-decoration: none;"
                                                    class="text-black link-megamenu ">Our Story</a>
                                            </li>

                                            <li class="link-megamenu mb-4 cursor-pointer" style="cursor:pointer"
                                                @mouseenter="initialID = 2">
                                                <a href='/milestone' style="text-decoration: none;"
                                                    class="text-black link-megamenu ">Milestone</a>
                                            </li>
                                            <li class="link-megamenu cursor-pointer" style="cursor:pointer"
                                                @mouseenter="initialID = 3" @click="route('management-team')">A Message from Our President Director
                                            </li>
                                        </ul>

                                    </div>
                                    <div class="content-here col-8 m-0 p-4">

                                        <h5 class="text-capitalize text-green fw-bold title">{{ currentMenuContent.subTitle
                                        }}</h5>
                                        <small>
                                            <p class="text-muted secondary-font description"> {{
                                                currentMenuContent.description }}</p>

                                        </small>
                                        <a @click="route(currentMenuContent.link)" class="btn btn-success">Learn More</a>



                                    </div>

                                </div>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-capitalize cursor-pointer" @click="toggleMegaMenu"
                                href="/business-pillar"
                                :class="[getNavLinkClasses, { 'routerActive': $route.name == 'business-pillar' }]"
                                style="cursor: pointer;">Business Pillars</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-capitalize" href="/investment-insight" style="cursor: pointer;"
                                :class="[getNavLinkClasses, { 'routerActive': $route.name == 'investment-insight' }]">Investment
                                Insights</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-capitalize" href="/csr" style="cursor: pointer;"
                                :class="[getNavLinkClasses, { 'routerActive': $route.name == 'csr' }]">CSR</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link text-capitalize" :class="getNavLinkClasses">Career</a>
                        </li> -->
                        <li class="nav-item">
                            <a class="nav-link text-capitalize" @click="route('contact-us')" style="cursor: pointer;"
                                :class="[getNavLinkClasses, { 'routerActive': $route.name == 'contact-us' }]">Contact Us</a>
                        </li>
                        <!-- <form class="d-flex ms-4">
                            <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                            <button class="btn btn-success" type="submit">Search</button>
                        </form> -->
                        <li class="nav-item">
                            <button type="button" @click="openModal" class="btn"><i class="fa fa-search hover-green"
                                    :class="getNavLinkClasses" aria-hidden="true"></i></button>
                        </li>
                    </ul>


                </div>
            </div>
        </nav>




    </header>


    <header class="header default bg-white d-md-block d-lg-none fixed-top">
        <nav class="navbar fixed-top navbar-expand-lg " :class="getMainNavClasses" aria-label="Thirteenth navbar example">
            <div class="container-lg d-flex justify-content-between">
                <a class="navbar-brand mx-0 d-lg-none w-25" href="/">
                    <img src="../assets/logo-wiraraja.png" alt="logo" class="img-fluid navbar-logo">
                </a>
                <button class="navbar-toggler d-lg-none" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
        </nav>
    </header>

    <div class="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions"
        aria-labelledby="offcanvasWithBothOptionsLabel">
        <div class="offcanvas-header">
            <a class="navbar-brand mx-0 d-lg-none w-50" href="/">
                <img src="../assets/logo-wiraraja.png" alt="logo" class="img-fluid navbar-logo">
            </a>

            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body mx-2">
            <ul class="navbar-nav  text-start ">
                <li class="nav-item py-2">
                    <a class="text-decoration-none text-black hover-green py-2 text-capitalize" :class="[{ 'routerActive': $route.name == 'home' }]" href="/">Home</a>

                </li>
                <li class="nav-item dropdown py-2">
                    <a class="text-decoration-none text-black hover-green py-2 text-capitalize cursor-pointer dropdown-toggle" href="#" @click="isOpen = !isOpen"
                    :class="[{ 'routerActive': $route.name == 'about-us' || $route.name == 'milestone' || $route.name == 'management-team' }]">
                        About Us
                    </a>

                    <ul v-if="isOpen" class="dropdown-menu p-2 border-0 shadow-sm">
                        <li><a class='text-decoration-none text-black hover-green text-capitalize cursor-pointer' href="/about-us">Our Story</a></li>
                        <li><a class='text-decoration-none text-black hover-green text-capitalize cursor-pointer' href="/milestone">Milestone</a></li>
                        <li><a class='text-decoration-none text-black hover-green text-capitalize cursor-pointer' href="/management-team">A Message from Our President Director</a></li>
                    </ul>


                </li>
                <li class="nav-item py-2">
                    <a class="text-decoration-none text-black hover-green text-capitalize cursor-pointer" :class="[{ 'routerActive': $route.name == 'business-pillar' }]" href="/business-pillar"
                        style="cursor: pointer;">Business
                        Pillars</a>
                </li>

                <li class="nav-item py-2">
                    <a class="text-decoration-none text-black hover-green text-capitalize" href="/investment-insight" :class="[{ 'routerActive': $route.name == 'investment-insight' }]" style="cursor: pointer;">Investment
                        Insights</a>
                </li>
                <li class="nav-item py-2">
                    <a class="text-decoration-none text-black hover-green text-capitalize" href="/csr" style="cursor: pointer;">CSR</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link text-capitalize" :class="getNavLinkClasses">Career</a>
                </li> -->
                <li class="nav-item py-2">
                    <a class="text-decoration-none text-black hover-green text-capitalize" href="/contact-us" :class="[{ 'routerActive': $route.name == 'contact-us' }]" style="cursor: pointer;">Contact Us</a>
                </li>
                <li class="nav-item py-2">
                    <button type="button" @click="openModal" class="btn"><i class="fa fa-search hover-green"
                            aria-hidden="true"></i></button>
                </li>


            </ul>
        </div>
    </div>
</template>
<script>

import { Icon } from '@iconify/vue';
import { ref, onMounted } from 'vue'

import axios from "axios";
import { gsap } from 'gsap';
export default {
    components: {

        Icon

    },
    setup() {
        const isOpen = ref(false);
        return { isOpen };
    },
    data() {
        return {
            loading: false,
            currentPage: 1,
            itemsPerPage: 4,
            search: null,
            searchModal: false,
            datas: [],
            searchBool: false,
            scrollingUp: true,
            scrollingDown: false,
            isVisible: true,
            megaMenuVisible: false,
            initialID: 1,
            megaMenuTimeout: null,
            prevScrollpos: window.pageYOffset,
            menuContent: [
                {
                    id: 1,
                    title: 'Our Story',
                    subTitle: 'Our Story',
                    link: 'about-us',
                    description: 'Discover who we are as a partner who provides tailored solutions for your business needs',

                },
                {
                    id: 2,
                    title: 'Milestone',
                    subTitle: 'Milestone',
                    link: 'milestone',
                    description: 'Learn more about our significant moments of accomplishment that mark our company\'s growth',

                },
                {
                    id: 3,
                    title: 'A Message from Our President Director',
                    subTitle: 'A Message from Our President Director',
                    link: 'management-team',
                    description: 'Get to know our management who drives our company\'s success',

                },


            ]
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.datas.length / this.itemsPerPage);
        },
        displayedItems() {
            let start = (this.currentPage - 1) * this.itemsPerPage;
            let end = start + this.itemsPerPage;
            return this.datas.slice(start, end);
        },
        getMainNavClasses() {
            return {
                'bg-transparent': this.scrollingUp,
                'bg-white shadow-sm': this.scrollingDown,
                'nav-visible': this.isVisible,
                'nav-hidden': !this.isVisible,
            };
        },
        getNavLinkClasses() {
            return {
                'text-white text-uppercase': this.scrollingUp,
                'text-black text-uppercase': this.scrollingDown
            };
        },
        currentMenuContent() {
            return this.menuContent.find(item => item.id === this.initialID);
        }


    },
    methods: {
        hrefSearch(link){
            // link = String(link).toLowerCase();
            if(link == "Business Pillar - Wiraraja Renewable Energy"){
                return '/business-pillar?pillar=renewable-energy'
            }
            else if(link == "Business Pillar - Wiraraja Industrial Park"){
                return '/business-pillar?pillar=industrial-park'
            }
            else if(link == "Business Pillar - Wiraraja Mining"){
                return '/business-pillar?pillar=mining'
            }
            else if(link == "Investment Insights"){
                return '/investment-insight'
            }
            else if(link == "FAQ Page"){
                return '/faq'
            }
            else if(link == "Contact Us"){
                return '/contact-us'
            }
            else if(link == "Gallery"){
                return '/gallery'
            }
            else if(link == "About Us - Management Team"){
                return '/management-team'
            }
            else if(link == "About Us - Milestone"){
                return '/milestone'
            }
            else if(link == "About Us - Our Story"){
                return '/about-us'
            }
            else if(link == "Career"){
                return '/career'
            }
            else if(link == "E-brochure"){
                return '/e-brochure'
            }
        },
        changePage(pageNumber) {
            this.currentPage = pageNumber;
        },
        async searchData(){
            this.currentPage = 1;
            this.loading = true;
            var link = null;
            if(this.search){
                link = "https://wiraraja.com/api/search?search=" + this.search;
            }
            else{
                link = "https://wiraraja.com/api/search"
            }
            await axios
            .get(link)
            .then((response) => {
                console.log(response.data.data);
                this.datas = response.data.data
            })
            .catch((error) => {

            })
            this.loading = false;
            this.searchBool = true;
        },
        openModal() {
            this.searchModal.handleUpdate()
            this.searchModal.show();
        },
        closeModal() {
            this.datas = [];
            this.currentPage = 1;
            this.search = null;
            this.searchBool = false;
            this.searchModal.hide();
        },
        route(name) {
            this.$router.push({ path: name })
        },
        scrollNow() {
            const currentScrollPos = window.pageYOffset;
            if (currentScrollPos > 100) {
                this.isVisible = false;
            } else {
                this.isVisible = true;
            }
            if (currentScrollPos < 100) {
                this.scrollingUp = true; // set this to true
                this.scrollingDown = false;
                return;
            }

            if (this.prevScrollpos > currentScrollPos && currentScrollPos > 100) {
                // up but not at the top
                this.scrollingDown = true;
                this.scrollingUp = false;
            } else if (this.prevScrollpos > currentScrollPos && currentScrollPos <= 100) {
                // up and at the top
                this.scrollingDown = false;
                this.scrollingUp = true;
            } else {
                // down
                this.scrollingUp = false;
                this.scrollingDown = true;
            }

            this.prevScrollpos = currentScrollPos;
        },


        handleScroll() {
            let doScoll;

            window.onscroll = () => {
                clearTimeout(doScoll);
                doScoll = setTimeout(this.scrollNow, 100); // firing less scroll events
            };
        },
        openMegaMenu() {
            clearTimeout(this.megaMenuTimeout);
            this.megaMenuVisible = true;
        },
        closeMegaMenu() {
            this.megaMenuTimeout = setTimeout(() => {
                this.megaMenuVisible = false;
            }, 500); // 500ms delay
        },
    },
    mounted() {
        this.searchModal = new bootstrap.Modal(document.getElementById('searchModal'), {})
    },

    created() {
        this.handleScroll();
    },

    destroyed() {
        window.onscroll = null;
    },
}
</script>
<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  min-width: 160px;
  z-index: 1;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.modal-content {
    background-color: rgba(240, 255, 247, 0.9) !important;
}


.modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}
</style>


<style>
.modal-backdrop {
    z-index: -1;
}

.hover-green {
    transition: all 0.3s ease;
}

.form-inputs {
    position: relative;
}

.form-inputs .form-control {
    height: 45px;
}

.form-inputs .form-control:focus {
    box-shadow: none;
    border: 1px solid #000;
}

/* .form-inputs i {
    position: absolute;
    right: 10px;
    top: 15px;
} */

.hover-green:hover {
    color: var(--primary-green) !important;
}

.routerActive {
    color: var(--primary-green) !important;
    border-bottom: 4px solid var(--primary-green);
}

.bg-wave {
    background-color: #ffffff;
    opacity: 1;
    background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #ffffff 29px), repeating-linear-gradient(#eeeeee55, #eeeeee);
}

.text-green {
    color: var(--primary-green);
}


.nav-link:hover {
    color: var(--primary-green) !important;
}

.bg-white,
.bg-transparent {
    transition: background-color 0.3s ease-in-out !important;
}

.cover-img {
    object-fit: cover;
}

.top-200 {
    top: 190% !important;
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY();
    }

    100% {
        transform: translateY(-100%);
    }
}

.nav-visible {
    animation: slideDown 0.5s forwards;
}

.nav-hidden {
    animation: slideDown 0.5s forwards;
}

.link-megamenu:hover {
    color: var(--primary-green) !important;
}

.default {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1040;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
}

.w-30 {
    width: 30% !important;
}

.nav-link .active {
    color: var(--yellow) !important;
}




</style>
